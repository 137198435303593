import { useContext, useState } from "react";
import { WretchResponse } from "wretch/types";
import NotificationDispatch, {
  showErrorNotification,
  showInformativeNotification,
  showSuccessNotification,
} from "../../context/notificationContext";
import useToggle from "../../hooks/useToggle";
import { SP } from "../../http/serviceportalApi";
import { downloadFile } from "../../utils";
import Button from "../ui/Button";
import ModalDialog from "../ui/ModalDialog";
import styles from "./DocumentsDownload.module.css";

interface Props {
  links: SP.DownloadLinks;
  fetchDocument: (link: string) => Promise<WretchResponse>;
  onDownloadSuccess: () => void;
}

const DocumentsDownload = ({
  links,
  fetchDocument,
  onDownloadSuccess,
}: Props) => {
  const dispatch = useContext(NotificationDispatch);
  const modal = useToggle();
  const [downloadsinProgress, setDownloadsInProgress] = useState(0);

  const downloadDocument = async (link: string) => {
    const res = await fetchDocument(link);
    switch (res.headers.get("Content-Type")) {
      case "application/pdf":
      case "application/xml":
      case "application/zip":
      case "text/plain":
      case "text/csv":
      case "application/octet-stream":
        await downloadFile(res);
        break;
      default:
        throw new Error("Unknown content type");
    }
  };

  const onDownloadAllClick = async (link: string | null) => {
    if (!link) return;
    setDownloadsInProgress(downloadsinProgress + 1);
    modal.close();
    dispatch(showInformativeNotification("Der Download wurde gestartet."));
    return downloadDocument(link)
      .then(() => {
        dispatch(
          showSuccessNotification(
            "Die Datei wurde erfolgreich heruntergeladen."
          )
        );
        onDownloadSuccess();
      })
      .catch((error) =>
        dispatch(
          showErrorNotification(error, "Fehler beim Herunterladen der Datei.")
        )
      )
      .finally(() => setDownloadsInProgress(downloadsinProgress - 1));
  };

  return (
    <>
      <Button
        isLoading={downloadsinProgress > 0}
        buttonProps={{
          className: styles.downloadButton,
          disabled: Object.values(links).every((l) => !l),
          onClick: modal.toggle,
        }}
      >
        Alle Treffer herunterladen
      </Button>

      <ModalDialog
        isOpen={modal.isOpen}
        onClose={modal.close}
        title="Alle Treffer herunterladen"
      >
        <div className={styles.modalContent}>
          Wählen Sie ein Format aus:
          <div className={styles.formatButtons}>
            {links.zip && (
              <DownloadButton
                title="ZIP"
                tooltip="Alle Dokumente einzeln in einer ZIP-Datei herunterladen"
                onClick={() => onDownloadAllClick(links.zip)}
              />
            )}

            {links.pdf && (
              <DownloadButton
                title="PDF"
                tooltip="Alle Dokumente in einer PDF-Datei herunterladen"
                onClick={() => onDownloadAllClick(links.pdf)}
              />
            )}

            {links.pdfWithoutMyGastroDocx && (
              <DownloadButton
                title="PDF ohne MyGastroDocx"
                tooltip="Alle Dokumente in einer PDF-Datei ohne die Lohnstreifen MyGastrodocx herunterladen"
                onClick={() => onDownloadAllClick(links.pdfWithoutMyGastroDocx)}
              />
            )}
          </div>
        </div>
      </ModalDialog>
    </>
  );
};

interface DownloadButtonProps {
  title: string;
  tooltip: string;
  onClick: () => void;
}

const DownloadButton = ({ title, tooltip, onClick }: DownloadButtonProps) => (
  <button className={styles.formatButton} title={tooltip} onClick={onClick}>
    {title}
  </button>
);

export default DocumentsDownload;
