import classNames from "classnames";
import { useContext, useState } from "react";
import { useLocation } from "react-router-dom";
import { WretchResponse } from "wretch/types";
import NotificationDispatch, {
  showErrorNotification,
} from "../../context/notificationContext";
import { UseListFiltersReturn } from "../../hooks/useListFilters";
import { SP } from "../../http/serviceportalApi";
import i18n from "../../i18n";
import { RemoteDataStatus } from "../../types";
import {
  downloadFile,
  isRouteEconomicAdvisors,
  isRouteRevisor
} from "../../utils";
import PdfBlobPreview from "../ui/PdfBlobPreview";
import Table from "../ui/Table";
import styles from "./DocumentsTable.module.css";

interface Props {
  setType: SP.DocumentSetType;
  paginationFilters: UseListFiltersReturn;
  documents: SP.Document[];
  total: number;
  remoteStatus: RemoteDataStatus;
  fetchDocument: (link: string) => Promise<WretchResponse>;
  markAsRead: (id: string) => void;
}

const DocumentsTable = ({
  setType,
  paginationFilters,
  documents,
  total,
  remoteStatus,
  fetchDocument,
  markAsRead,
}: Props) => {
  const dispatch = useContext(NotificationDispatch);
  const location = useLocation();

  const showCustomerColumn = isRouteEconomicAdvisors(location);
  const showMemberColumn = isRouteRevisor(location);
  const showDepartmentColumn = isRouteRevisor(location);
  const showEmployeeColumn =
    setType !== "lohn-rundschreiben" &&
    setType !== "steuer-rundschreiben" &&
    !showCustomerColumn &&
    !showMemberColumn &&
    !showDepartmentColumn;

  return (
    <Table
      classNames={{ tableWrapper: styles.tableWrapper, table: styles.table }}
      filters={paginationFilters}
      total={total}
      remoteDataStatus={remoteStatus}
      head={
        <tr>
          <th className={styles.readStatusCol}></th>
          {showDepartmentColumn && (
            <th className={styles.departmentCol}>Abteilung</th>
          )}
          {showMemberColumn && (
            <th className={styles.memberCol}>Mitgliedsnummer</th>
          )}
          {showCustomerColumn && <th>Kunde</th>}
          <th className={styles.dateCol}>Datum</th>
          <th
            className={classNames(styles.typeCol, {
              [styles.typeColWide]: !showEmployeeColumn,
            })}
          >
            Dokument
          </th>
          {showEmployeeColumn && (
            <th className={styles.employeeCol}>Mitarbeiter</th>
          )}
          {!showCustomerColumn &&
            !showMemberColumn &&
            !showDepartmentColumn && (
              <th className={styles.myGastroDocxCol}>MyGastroDocx</th>
            )}
        </tr>
      }
      body={documents.map((doc) => (
        <DocumentRow
          key={doc.id}
          document={doc}
          showMemberColumn={showMemberColumn}
          showDepartmentColumn={showDepartmentColumn}
          showEmployeeColumn={showEmployeeColumn}
          showCustomerColumn={showCustomerColumn}
          fetchDocument={fetchDocument}
          onDownloadSuccess={() => {
            if (doc.readByCurrentUser) return;
            markAsRead(doc.id);
          }}
          onDownloadFailure={(error) =>
            dispatch(
              showErrorNotification(error, "Fehler beim Öffnen des Dokuments.")
            )
          }
        />
      ))}
    />
  );
};

interface DocumentRowProps {
  document: SP.Document;
  showMemberColumn: boolean;
  showDepartmentColumn: boolean;
  showEmployeeColumn: boolean;
  showCustomerColumn: boolean;
  fetchDocument: (link: string) => Promise<WretchResponse>;
  onDownloadSuccess: () => void;
  onDownloadFailure: (error: unknown) => void;
}

const TypeDVEAU = "Verschiedene Auswertungen und Dokumente";

const DocumentRow = ({
  document: {
    readByCurrentUser,
    downloadLink,
    date,
    type,
    employee,
    customer,
    myGastroDocx,
    member,
    department,
    fileName,
  },
  showMemberColumn,
  showDepartmentColumn,
  showEmployeeColumn,
  showCustomerColumn,
  fetchDocument,
  onDownloadSuccess,
  onDownloadFailure,
}: DocumentRowProps) => {
  const [pdf, setPdf] = useState<Blob>();

  if (showDepartmentColumn || type === TypeDVEAU) {
    type = fileName;
  }

  return (
    <tr className={classNames({ [styles.unread]: !readByCurrentUser })}>
      <td className={styles.readStatusCol}>{!readByCurrentUser && "•"}</td>
      {showDepartmentColumn && (
        <td className={styles.departmentCol}>
          <span className={styles.department}>
            {i18n.getDepartment(department as SP.Department)}
          </span>
        </td>
      )}
      {showMemberColumn && (
        <td className={styles.memberCol}>
          <span className={styles.member}>{member}</span>
        </td>
      )}
      {showCustomerColumn && <td>{customer}</td>}
      <td className={styles.dateCol}>
        {date}
      </td>
      <td className={styles.typeCol}>
        <a
          href="#"
          onClick={async (e) => {
            e.preventDefault();
            try {
              const res = await fetchDocument(downloadLink);
              switch (res.headers.get("Content-Type")) {
                case "application/pdf":
                  const blob = await res.blob();
                  setPdf(blob);
                  break;
                default:
                  await downloadFile(res);
              }
              onDownloadSuccess();
            } catch (error) {
              onDownloadFailure(error);
            }
          }}
          title={type}
        >
          {type}
        </a>
        <PdfBlobPreview title={type} blob={pdf} />
      </td>
      {showEmployeeColumn && (
        <td className={styles.employeeCol}>
          <span className={styles.employee}>{employee?.toLowerCase()}</span>
        </td>
      )}
      {!showCustomerColumn && !showMemberColumn && !showDepartmentColumn && (
        <td>{myGastroDocx && "Ja"}</td>
      )}
    </tr>
  );
};

export default DocumentsTable;
